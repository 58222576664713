import { Modal, notification } from 'antd';
import React, { useState } from 'react';
import styles from './CancelAppointmentModal.module.scss';
import Button, { HelmBtnStatus } from 'components/v2/Button/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { cancelAppointment } from 'utils/http/SchedService/Appointments/cancellation';
import { useNavigate } from 'react-router-dom';
import { IS_RECHARGE_APP, IS_SELECT_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { SelectTermsAndConditions } from 'Select/utils/env/SelectEnv';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import { RechargePrivacyPolicyUrl } from 'Recharge/utils/RechargeEnv/RechargeEnv';

interface CancelAppointmentModalProps {
  appointmentId: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  modalWrapperClassName?: string;
}

/**
 * Currently For Someone Health and Select only
 */
const CancelAppointmentModal = ({
  appointmentId,
  setVisible,
  visible,
  modalWrapperClassName
}: CancelAppointmentModalProps) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { CancellationPolicy } = someoneHealthEnvironment();

  const [status, setStatus] = useState<HelmBtnStatus>('');

  const handleConfirm = async () => {
    setStatus('active');
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_API_AUDIENCE
    });
    if (token) {
      try {
        const response = await cancelAppointment(token, appointmentId);
        if (response.statusCode === 204) {
          notification.success({
            message: 'Appointment successfully deleted'
          });
          setStatus('finished');
          setVisible(false);
          navigate(0);
        } else {
          const result = await response.json();
          notification.error({
            message: result.message || 'Something went wrong while trying to cancel this appointment.'
          });
          setStatus('');
        }
      } catch (ex) {
        notification.error({
          message: 'Something went wrong while trying to cancel this appointment.'
        });
        setStatus('');
      }
    }
  };

  return (
    <Modal
      title="Are you sure you want to cancel?"
      visible={visible}
      className={styles.container}
      wrapClassName={modalWrapperClassName}
      width={'fit-content'}
      footer={null}
      closable={false}
      zIndex={1051}
    >
      <div className={styles.content}>
        Please note that if you are giving us less than 48 hours notice, you may be liable to pay a late cancellation
        charge in line with our{' '}
        <a
          href={
            IS_RECHARGE_APP ? RechargePrivacyPolicyUrl : IS_SELECT_APP ? SelectTermsAndConditions : CancellationPolicy
          }
        >
          cancellation policy
        </a>
        .
      </div>
      <div className={styles.actionWrapper}>
        <Button variant="secondary" onClick={() => setVisible(false)} type="button">
          No, keep my appointment
        </Button>
        <Button variant="primary" onClick={handleConfirm} status={status} type="button">
          Confirm Cancellation
        </Button>
      </div>
    </Modal>
  );
};

export default CancelAppointmentModal;
