import { notification } from 'antd';
import Loading from 'components/Loading/Loading';
import { useState } from 'react';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { postStripePaymentMethod } from 'utils/http/BillingService/stripe';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './AddPayment.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faDollar, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';
import classNames from 'classnames';

const AddPayment = () => {
  const signedUpUser = retrieveUserSession();
  const { SIGNUP_INVITE } = useSomeoneHealthRoutesGenerator();
  const { CancellationPolicy, PricingURL } = someoneHealthEnvironment();

  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  const onAddPaymentDetails = async () => {
    setButtonStatus('active');
    try {
      const response = await postStripePaymentMethod({
        token: signedUpUser.authToken || '',
        payload: {
          returnUrl: `${window.location.origin}${SIGNUP_INVITE.WELCOME_INVITE}`,
          cancelUrl: `${window.location.origin}${SIGNUP_INVITE.ADD_PAYMENT}`
        }
      });
      setButtonStatus('finished');
      window.location.href = await response.text();
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to generate the link. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Add payment'}>
      <div className={classNames(styles.pageContainer, 's1h-client-portal-theme')}>
        <SomeoneHealthHeader noLogin classNames={styles.header} />
        {!signedUpUser && <Loading />}
        <SomeoneHealthContentLayout className={styles.container}>
          <div className={styles.title}>Cancellation and charging summary</div>
          <div className={styles.content}>
            Welcome {signedUpUser.firstName || signedUpUser.clientRecord?.clientProfiles[0].firstName}
            <br />
            <br />
            The next step to create your account is to provide payment details. Rest assured, we handle your financial
            details with the utmost care and only use them as necessary for your chosen appointments.
            <br />
            <br />
            <div className={styles.description}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon className={styles.icon} icon={faStopwatch} />
              </div>
              <div>Appointment changes are free of charge as long as you provide 48 hours notice.</div>
            </div>
            <div className={styles.description}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon className={styles.icon} icon={faCalendar} />
              </div>
              <div>Appointment changes within 48 hours or a ‘no show’ will be charged a $40 fee.</div>
            </div>
            <div className={styles.description}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon className={styles.icon} icon={faDollar} />
              </div>
              <div>
                <div>
                  <b>Medicare bulk bill</b> appointments will not incur a charge.
                </div>
                <div>
                  <b>Medicare rebate</b> and <b>self fund</b> appointments will be charged two days before your
                  appointment. For more information, see our{' '}
                  <a className={styles.link} href={PricingURL}>
                    pricing
                  </a>
                </div>
              </div>
            </div>
            <br />
            By proceeding, you agree to the{' '}
            <a className={styles.link} href={CancellationPolicy}>
              cancellation policy
            </a>
            <div>
              <ButtonSH className={styles.button} onClick={onAddPaymentDetails} status={buttonStatus}>
                Securely save my payment details
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default AddPayment;
